@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');

* {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
}

html {
  /*overflow-y: scroll;*/
  /*height: 100%;*/
  /*-webkit-overflow-scrolling: touch;*/
  /*overflow-scrolling: touch;*/
}
body {
  /*overflow-y: visible;*/
  /*position: relative;*/
  /*overflow-x: hidden;*/
}

.container {
  max-width: 1320px;
  padding: 0 20px;
  margin: 0 auto;
  box-sizing: border-box;
}
