@import '../../assets/styles/app';

.header {
  // @include container;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  transition: 0.3s;

  &::before {
    position: absolute;
    z-index: 2;
    content: '';
    display: block;
    left: 0;
    bottom: 0;
    right: 0;
    height: 1px;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.09),
      rgba(255, 255, 255, 0)
    );
  }

  .gradientLeft {
    @include onlyDesktop();
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 284px;
    height: auto;
  }

  .gradientRight {
    @include onlyDesktop();
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
  }

  .inner {
    // @include inner;
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 75px;
    transition: 0.3s;

    @media (min-width: 992px) {
      height: 102px;
    }

    .logo {
      display: block;

      svg {
        width: 145px;
        height: 28px;

        @media (min-width: 992px) {
          width: 160px;
          height: 30.89px;
        }
      }
    }

    .links {
      @include absoluteCenter;
      @include centerRow;

      display: none;

      @media (min-width: 992px) {
        display: flex;
      }

      .item {
        &:not(:first-child) {
          margin-left: 30px;
        }
      }

      .link {
        text-decoration: none;
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        letter-spacing: -0.02em;
        color: #ffffff;
        white-space: nowrap;
        transition: 0.3s;

        &:not(:first-child) {
          margin-left: 30px;
        }

        &:hover {
          color: #02a4c7;
        }
      }
    }

    .tradeBtn {
      width: 130px;
      display: none;

      @media (min-width: 992px) {
        display: block;
      }
    }

    .burgerBtn {
      @include button;
      @include centerRow;
      width: 30px;
      height: 30px;

      display: block;

      @media (min-width: 992px) {
        display: none;
      }
    }
  }

  &_scroll {
    background: rgba(17, 19, 31, 0.9);
    backdrop-filter: blur(6px);

    .inner {
      height: 75px;
      // display: none;

      @media (min-width: 992px) {
        display: flex;
      }
    }
  }

  &_hide {
    transform: translateY(-101%);
  }
}
