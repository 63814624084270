@import "../../../assets/styles/app";

.dropDownHeader {
  position: relative;
  z-index: 101;

  .btn {
    @include button;
    display: flex;
    align-items: flex-end;

    p {
      font-weight: 400;
      font-size: 13px;
      line-height: 20px;
      letter-spacing: -0.02em;
      color: #FFFFFF;
      white-space: nowrap;
      transition: 0.3s;
    }

    svg {
      margin-left: 6px;
      margin-bottom: 5px;
      transition: 0.3s;

      path {
        transition: 0.3s;
      }
    }

    &_open {

      p {
        color: #02A4C7;
      }

      svg {
        transform: rotate(-180deg);

        path {
          fill: #02A4C7;
        }
      }
    }
  }

  .dropDownWrapper {
    position: absolute;
    z-index: 200;
    left: 0;
    top: 20px;
    padding-top: 10px;

    .dropDown {
      padding: 15px;
      box-sizing: border-box;
      width: 146px;
      background: rgba(21, 24, 40, 0.8);
      backdrop-filter: blur(12px);
      border-radius: 10px;
      border: 1px solid rgba(255, 255, 255, 0.06);

      .item {
        display: block;
        text-decoration: none;
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        letter-spacing: -0.02em;
        color: #FFFFFF;
        white-space: nowrap;
        transition: 0.3s;

        &:not(:first-child) {
          margin-top: 10px;
        }

        &:hover {
          color: #02A4C7;
        }
      }

    }
  }


}

.popoverlistItem {
  display: block;
  text-decoration: none;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: #FFFFFF;
  white-space: nowrap;
  transition: 0.3s;

  &:not(:first-child) {
    margin-top: 10px;
  }

  &:hover {
    color: #02A4C7;
  }
}
