//========= BREAK POINTS ===========//
@mixin desktop {
  @media all and (min-width: 1290px) { // 1440px
    @content;
  }
}

@mixin onlyMobile($displayType: block) {
  display: $displayType;
  @content;

  @include desktop {
    display: none;
  }
}

@mixin onlyDesktop($displayType: block) {
  display: none;

  @include desktop {
    display: $displayType;
    @content;
  }
}

//========= OTHER ===========//
@mixin centerColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@mixin centerRow {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin button {
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
}

@mixin absoluteCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin container {
  padding-left: 20px;
  padding-right: 20px;

  @include desktop {
    padding-left: 0;
    padding-right: 0;
  }
}

@mixin inner {
  @include desktop {
    width: 1280px;
    margin-left: auto;
    margin-right: auto;
  }
}

@mixin h2_block {

  .pretext {
    font-weight: 500;
    font-size: 12px;
    line-height: 115%;
    text-align: center;
    letter-spacing: -0.02em;
    text-transform: uppercase;

    @include desktop {
      font-size: 14px;
    }
  }

  h2.title {
    margin-top: 10px;
    font-weight: 600;
    font-size: 24px;
    line-height: 130%;
    text-align: center;
    letter-spacing: -0.02em;

    @include desktop {
      margin-top: 15px;
      font-size: 36px;
    }
  }
}

@mixin h2_block_white {

  .pretext {
    color: #707179;
  }

  h2.title {
    color: #FFFFFF;
  }
}

@mixin h2_block_black {

  .pretext {
    color: #9A99AF;
  }

  h2.title {
    color: #0E0B3D;
  }
}

@mixin gradient-text {
  background: linear-gradient(34.49deg, #0781FE -12.58%, #00C7B4 102.84%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
