@import '../../assets/styles/app';

.footer {
  position: relative;
  z-index: 2;
  padding-bottom: 20px;

  @media (min-width: 1000px) {
    padding-bottom: 30px;
  }

  .inner {
    padding-left: 0;
    padding-right: 0;
  }

  .top {
    @include container;
    position: relative;
    padding-top: 30px;
    padding-bottom: 30px;

    &::before {
      display: block;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 1px;
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0.09),
        rgba(255, 255, 255, 0)
      );

      @media (min-width: 1000px) {
        display: none;
      }
    }

    @media (min-width: 1000px) {
      padding-top: 0;
      padding-bottom: 25px;
    }

    .inner {
      @media (min-width: 1000px) {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .logo {
        display: none;

        @media (min-width: 1000px) {
          display: block;

          svg {
            width: 160px;
            height: 31px;
          }
        }
      }

      .linksMobile {
        display: grid;
        grid-template-columns: repeat(2, 1fr);

        @media (min-width: 1000px) {
          display: none;
        }

        .column {
          .link {
            display: block;
            text-decoration: none;
            font-weight: 400;
            font-size: 13px;
            line-height: 20px;
            letter-spacing: -0.02em;
            color: #fafafa;

            &:not(:first-child) {
              margin-top: 20px;
            }
          }
        }
      }

      .linksDesktop {
        display: none;

        @media (min-width: 1000px) {
          display: flex;
        }

        .link {
          text-decoration: none;
          font-weight: 400;
          font-size: 13px;
          line-height: 20px;
          letter-spacing: -0.02em;
          color: #fafafa;
          transition: 0.3s;

          &:not(:first-child) {
            margin-left: 30px;
          }

          &:hover {
            color: #02a4c7;
          }
        }
      }
    }
  }

  .bottom {
    @include container;
    position: relative;
    padding-top: 20px;

    @media (min-width: 1000px) {
      padding-top: 25px;
    }

    &::before {
      display: block;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 1px;
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0.09),
        rgba(255, 255, 255, 0)
      );
    }

    .inner {
      // @include inner;

      @media (min-width: 1000px) {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
      }

      .socialLinks {
        display: flex;

        .link {
          width: 18px;
          height: 18px;

          &:not(:first-child) {
            margin-left: 18px;
          }

          svg {
            path {
              fill: rgba(255, 255, 255, 0.6);
              transition: 0.3s;
            }
          }

          &:hover {
            svg {
              path {
                fill: rgba(255, 255, 255, 1);
              }
            }
          }
        }
      }

      .text {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        letter-spacing: -0.02em;
        color: #fafafa;
        opacity: 0.6;
        margin-top: 20px;

        @media (min-width: 1000px) {
          margin-top: 0;
        }
      }
    }
  }

  .rightsBlock {
    @include container;
    margin-top: 30px;

    .inner {
      // @include inner;

      .rightsBlock_top {
        display: flex;

        .expandBtn {
          @include button;
          @include centerRow;
          position: relative;
          top: -5px;
          width: 24px;
          height: 24px;
          //margin-left: 12px;

          svg {
            transform: rotate(0deg);
            transition: 0.3s;
          }

          &_open {
            svg {
              transform: rotate(-180deg);
            }
          }
        }
      }

      .text {
        font-weight: 400;
        font-size: 11px;
        line-height: 16px;
        letter-spacing: -0.02em;
        color: #9d9ea2;
        margin: 0 10px 0 0;

        @media (min-width: 1000px) {
          font-size: 12px;
          line-height: 18px;
        }

        &_1 {
          color: #707179;
          margin-top: 20px;

          @media (min-width: 1000px) {
            margin-top: 24px;
          }
        }
      }
    }
  }
}
