@import '../../../assets/styles/app';

.affiliateModal {
  .card {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 20px;
    width: calc(100vw - 40px);
    background: #ffffff;
    backdrop-filter: blur(17px);
    border-radius: 15px;
    box-sizing: border-box;
    padding: 40px 20px;
    max-height: calc(100vh - 40px);
    overflow: auto;

    @media (min-width: 768px) {
      left: 50%;
      width: 514px;
      transform: translate(-50%, -50%);
      padding: 50px 60px 60px;
      overflow: auto;
    }

    &:focus-visible {
      outline: none;
    }

    .closeBtn {
      @include button;
      position: absolute;
      top: 15px;
      right: 15px;
      width: 14px;
      height: 14px;

      @media (min-width: 768px) {
        width: 16px;
        height: 16px;
        top: 20px;
        right: 20px;
      }

      svg {
        width: 14px;
        height: 14px;

        @media (min-width: 768px) {
          width: 16px;
          height: 16px;
        }
      }
    }

    .title {
      font-weight: 600;
      font-size: 22px;
      line-height: 130%;
      text-align: center;
      letter-spacing: -0.02em;
      color: #0e0b3d;

      @media (min-width: 768px) {
        font-size: 32px;
      }
    }

    .description {
      margin-top: 12px;
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
      text-align: center;
      letter-spacing: -0.01em;
      color: #3e3c64;

      @media (min-width: 768px) {
        margin-top: 15px;
      }
    }

    .form {
      margin-top: 24px;

      .top {
        @media (min-width: 768px) {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-column-gap: 10px;
        }

        .field {
          &:not(:first-child) {
            margin-top: 15px;

            @media (min-width: 768px) {
              margin-top: 0;
            }
          }
        }
      }

      & > .field {
        margin-top: 15px;
      }

      .joinBtn {
        margin-top: 20px;
      }
    }
  }
}
