@import "../../assets/styles/app";

.app {
  background: #11131F;
  position: relative;
  overflow: hidden;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  .starWrapper {
    @include onlyDesktop;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
  }

  .main {
    flex-grow: 1;
    position: relative;
    z-index: 2;
  }

  &_burger {
    // height: 100vh;
    // box-sizing: border-box;
  }
}
