@import '../../../assets/styles/app';

.joinModal {
  .card {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 20px;
    width: calc(100vw - 40px);
    background: #ffffff;
    backdrop-filter: blur(17px);
    border-radius: 15px;
    box-sizing: border-box;
    padding: 40px 20px;

    @media (min-width: 768px) {
      left: 50%;
      width: 514px;
      transform: translate(-50%, -50%);
      padding: 50px 60px 40px;
    }

    &:focus-visible {
      outline: none;
    }

    .closeBtn {
      @include button;
      position: absolute;
      top: 15px;
      right: 15px;
      width: 14px;
      height: 14px;

      @media (min-width: 768px) {
        width: 16px;
        height: 16px;
        top: 20px;
        right: 20px;
      }

      svg {
        width: 14px;
        height: 14px;

        @media (min-width: 768px) {
          width: 16px;
          height: 16px;
        }
      }
    }

    .title {
      font-weight: 600;
      font-size: 22px;
      line-height: 130%;
      text-align: center;
      letter-spacing: -0.02em;
      color: #0e0b3d;

      @media (min-width: 768px) {
        font-size: 32px;
      }
    }

    .description {
      margin-top: 12px;
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
      text-align: center;
      letter-spacing: -0.01em;
      color: #3e3c64;

      @media (min-width: 768px) {
        margin-top: 15px;
      }

      span {
        font-weight: 600;
      }
    }

    .form {
      margin-top: 25px;

      @media (min-width: 768px) {
        margin-top: 30px;
      }

      .top {
        @media (min-width: 768px) {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-column-gap: 10px;
        }

        .field {
          &:not(:first-child) {
            margin-top: 15px;

            @media (min-width: 768px) {
              margin-top: 0;
            }
          }
        }
      }

      & > .field {
        margin-top: 15px;
      }

      .checkboxes {
        margin-top: 24px;

        &_item {
          display: flex;
          align-items: center;

          &:not(:first-child) {
            margin-top: 8px;

            @include desktop {
              margin-top: 6px;
            }
          }

          .item_text {
            margin-left: 8px;
            color: #3E3C64;
            font-size: 12px;
            line-height: 150%;
            letter-spacing: -0.12px;

            @include desktop {
              font-size: 14px;
            }
          }

          .item_link {
            @include gradient-text;
            text-decoration: none;
          }
        }

        .error {
          font-size: 12px;
          color: #d32f2f;
          margin-left: 14px;
        }
      }

      .joinBtn {
        margin-top: 20px;
      }
    }

    .text {
      margin-top: 16px;
      font-style: italic;
      font-weight: 400;
      font-size: 10px;
      line-height: 15px;
      letter-spacing: -0.02em;
      color: #6c6b7d;
      text-align: center;

      @media (min-width: 768px) {
        margin-top: 20px;
        font-size: 12px;
      }
    }
  }
}
