@import "../../../assets/styles/app";

.anchorCustom {
  @include centerRow;
  text-decoration: none;
  width: 100%;
  height: 42px;
  border-radius: 12px;
  background: linear-gradient(85.01deg, #0072E7 6.26%, #00AA9A 92.45%);
  transition: 0.3s;

  &:hover {
    background: linear-gradient(34.49deg, #0781FE -12.58%, #00C7B4 102.84%);
  }

  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.03em;
    color: #FFFFFF;
  }

  &_secondary {
    background: rgba(2, 164, 199, 0.12);
    border: 1px solid #02AFC7;

    &:hover {
      background: rgba(2, 164, 199, 0.24);
    }
  }

  svg {
    margin-left: 8px;
  }

  &:disabled {
    border: 1px solid #DDDDDD;
    background: none;
    cursor: auto;

    p {
      color: #8C8C8C;
    }

    svg {
      path {
        fill: #8C8C8C;
      }
    }
  }
}
