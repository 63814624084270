@import "../../assets/styles/app";

.burgerMenu {
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
  z-index: 50;
  transform: translateX(100%);
  background: #11131F;
  transition: 0.3s;
  padding: 115px 20px 30px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: auto;

  &_open {
    transform: translateX(0%);
  }

  .top {
    position: absolute;
    left: 20px;
    top: 90px;
    right: 20px;

    .item {
      &:not(:first-child) {
        margin-top: 16px;//24px;
      }
    }

    .link {
      text-decoration: none;
      font-weight: 400;
      font-size: 18px;
      line-height: 27px;
      letter-spacing: -0.02em;
      color: #FFFFFF;
    }
  }

  .bottom {
    position: absolute;
    left: 20px;
    bottom: 70px;
    right: 20px;
    //margin-bottom: 40px;

    .socialLinks {
      margin-top: 24px;
      display: flex;

      .link {
        @include centerRow;
        position: relative;
        z-index: 1;
        width: 42px;
        height: 42px;
        border-radius: 50%;
        background: rgba(2, 164, 199, 0);
        border: 1px solid #282A35;
        transition: 0.3s;

        &:not(:first-child) {
          margin-left: 10px;
        }

        svg {
          position: relative;
          z-index: 3;

          path {
            fill: #FFF;
          }
        }


      }
    }
  }

}
